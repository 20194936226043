<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img class="w-7 h-7 rounded-full" *ngIf="showAvatar && user?.avatar" [src]="user?.avatar">
        <mat-icon *ngIf="!showAvatar || !user?.avatar" [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>Signed in as</span>
            <span class="mt-1.5 text-md font-medium">{{ user.email || user.username}}</span>
            <span *ngIf="user.token && user.token.role" class="mt-1.5 text-md font-medium">{{ user.token.role }}</span>
            <span *ngIf="user.role" class="mt-1.5 text-md font-medium">{{ user.role }}</span>

        </span>
    </button>
    <!-- <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Profile</span>
    </button>
    <button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
        <span>Settings</span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="userStatus">
        <mat-icon
            [svgIcon]="'heroicons_outline:ellipsis-horizontal-circle'"
        ></mat-icon>
        <span>Status</span>
    </button> -->
    <!-- <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="updatePassword()">
        <mat-icon [svgIcon]="'mat_outline:update'"></mat-icon>
        <span>Update Password</span>
    </button> -->
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
        <span>Sign out</span>
    </button>
</mat-menu>

<mat-menu class="user-status-menu" #userStatus="matMenu">
    <button mat-menu-item (click)="updateUserStatus('online')">
        <span class="mr-3 inline-flex h-4 w-4 rounded-full bg-green-500"></span>
        <span>Online</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('away')">
        <span class="mr-3 inline-flex h-4 w-4 rounded-full bg-amber-500"></span>
        <span>Away</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('busy')">
        <span class="mr-3 inline-flex h-4 w-4 rounded-full bg-red-500"></span>
        <span>Busy</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('not-visible')">
        <span class="mr-3 inline-flex h-4 w-4 rounded-full bg-gray-400"></span>
        <span>Invisible</span>
    </button>
</mat-menu>

<s-modal id="updatepassword">
    <form #addProjectNgForm="ngForm" autocomplete="off">
        <mat-card class="flex w-full flex-row is-align-items-flex-start py-0 pl-0">
            <mat-card class="modal w-[500px] p-2">
                <div class="flex flex-row items-center">
                    <div class="text-2xl font-semibold tracking-tight">Update Password</div>
                    <button class="ml-auto" mat-icon-button (click)="modalService.close('updatepassword');">
                        <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                    </button>
                </div>
                <mat-divider class="mt-4"></mat-divider>
                <mat-card-content class="mt-6">
                    <div class="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1  gap-4  w-full">
                        <mat-form-field class="w-full">
                            <input class="ml-3" matInput [(ngModel)]="old_password" [required]="true"
                                name="old_password">
                            <mat-label>Old Password</mat-label>
                            <!-- <mat-icon class="icon-size-5" matSuffix matTooltip="Please select Rate Per Unit"
                                [svgIcon]="'feather:info'"></mat-icon> -->
                            <mat-error>
                                Please enter Old Password
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="w-full">
                            <input class="ml-3" matInput [(ngModel)]="new_password" [required]="true"
                                name="new_password">
                            <mat-label>New Password</mat-label>
                            <!-- <mat-icon class="icon-size-5" matSuffix matTooltip="Please select Rate Per Unit"
                                [svgIcon]="'feather:info'"></mat-icon> -->
                            <mat-error>
                                Please enter New Password
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="w-full">
                            <input class="ml-3" matInput [(ngModel)]="confirm_password" [required]="true"
                                name="confirm_password">
                            <mat-label>Confirm Password</mat-label>
                            <!-- <mat-icon class="icon-size-5" matSuffix matTooltip="Please select Rate Per Unit"
                                [svgIcon]="'feather:info'"></mat-icon> -->
                            <mat-error>
                                Please enter New Password
                            </mat-error>
                        </mat-form-field>
                    </div>
                </mat-card-content>
                <mat-divider class="mt-4"></mat-divider>
                <mat-card-footer class="mat-card-footer text-right pt-3 pb-3 pr-3 toolbar-top">
                    <button mat-flat-button [color]="'primary'" class="mat-primary mr-2"
                        [disabled]="addProjectNgForm.form.invalid" (click)="saveUpdatedPassword()">
                        Save
                    </button>
                    <button mat-stroked-button class="mat-warn" id="cancel11"
                        (click)="modalService.close('updatepassword')">
                        Cancel
                    </button>
                </mat-card-footer>
            </mat-card>
        </mat-card>
    </form>
</s-modal>